.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.footer {
  font-size: calc(5px + 2vmin);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 2px;
  text-align: center;
  color: gray;
  opacity: 0.5;
}

.logo {
  font-size: calc(10px + 2vmin);
  position: absolute;
  margin: 20px;
  left: 0;
  top: 0;
  text-align: center;
  color: #323437;
}

.central-gif {
  min-width: 200px;
  max-width: 70vw;
  width: 40vw;
}
